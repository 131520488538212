import { useState, useEffect } from 'react';
import {
	Autocomplete,
	CircularProgress,
	Grid,
	Typography,
	Link,
	FormControlLabel,
	Radio,
} from '@mui/material';
import { sortBy } from 'lodash';

import StyledCustomTextField from 'app/shared/components/StyledTextField';
import UserAvatarWithCircularText from 'app/shared/components/UserAvatarWithCircularText';
import { toCamelCase } from 'app/shared/util/ToCamelCase';

const DEBOUNCE_DELAY = 500; // Delay time in ms

const PaginatedAutocomplete = ({
	handleUserSelect,
	fetchOptions,
	t,
	handleAddUserClick,
	selectedUser,
	disabled,
	label,
	id,
	excludeIds = [],
}: any) => {
	const [inputValue, setInputValue] = useState(''); // Track user input
	const [options, setOptions] = useState<any[]>([]); // Holds fetched options
	const [page, setPage] = useState(1); // Track the current page
	const [isLoading, setIsLoading] = useState(false); // Track loading state
	const [hasMore, setHasMore] = useState(true); // Check if more data is available
	const [debouncedInput, setDebouncedInput] = useState(inputValue); // Track debounced input

	// Debouncing logic using useEffect
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedInput(inputValue); // Update debounced input after delay
		}, DEBOUNCE_DELAY);

		// Cleanup the timeout if input changes within the delay
		return () => clearTimeout(handler);
	}, [inputValue]);

	useEffect(() => {
		// Fetch data based on debounced input
		const fetchInitialData = async () => {
			setIsLoading(true);
			const response = await fetchOptions(debouncedInput, 1); // Use debounced input here
			setOptions(response?.data || []);
			setPage(1);
			setHasMore(response?.data?.length !== response?.totalCount); // Set `hasMore` based on the response length
			setIsLoading(false);
		};

		fetchInitialData();
	}, [debouncedInput]); // Trigger fetch when debouncedInput changes

	// Function to load more data when the "Load More" option is clicked
	const loadMore = async () => {
		if (isLoading || !hasMore) return; // Prevent fetching if already loading or no more data
		setIsLoading(true);
		const response = await fetchOptions(inputValue, page + 1); // Fetch the next page
		setOptions((prevOptions) => [...prevOptions, ...(response?.data || [])]); // Append new data
		setPage((prevPage) => prevPage + 1); // Increment the page number
		setHasMore(response?.data?.length !== response?.totalCount); // Set `hasMore` based on the response
		setIsLoading(false);
	};

	return (
		<>
			{label && (
				<Typography className="uppercase label" sx={{ marginBottom: '1rem' }}>
					{label}
				</Typography>
			)}
			<Autocomplete
				id={id || 'grouped-demo'}
				inputValue={inputValue}
				value={selectedUser || null} // Replace with your selected value if any
				onChange={(event, newValue) => {
					handleUserSelect(event, newValue);
				}}
				onInputChange={(event, newInputValue) => setInputValue(newInputValue)} // Capture user input
				options={
					hasMore
						? sortBy(
								[...options, { label: 'Load More', loadMore: hasMore }]?.filter(
									(x: any) => !excludeIds?.includes(x?.id)
								),
								'firstName'
						  )
						: sortBy(
								options?.filter((x: any) => !excludeIds?.includes(x?.id)),
								'firstName'
						  )
				}
				groupBy={(option) => option.firstLetter}
				getOptionLabel={(option) =>
					option.loadMore ? 'Load More' : toCamelCase(option?.firstName, option?.lastName)
				}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				filterOptions={(opts, { inputValue }) =>
					opts.filter((option) => {
						const firstNameMatches = option?.firstName
							?.toLowerCase()
							.includes(inputValue?.toLowerCase());
						const lastNameMatches = option?.lastName
							?.toLowerCase()
							.includes(inputValue?.toLowerCase());
						const fullNameMatches =
							`${option?.firstName?.toLowerCase()} ${option?.lastName?.toLowerCase()}`.includes(
								inputValue?.toLowerCase()
							);
						const phoneMatches = option?.phone?.includes(inputValue);
						const emailMatches = option?.email
							?.toLowerCase()
							.includes(inputValue?.toLowerCase());

						return (
							firstNameMatches ||
							lastNameMatches ||
							fullNameMatches ||
							phoneMatches ||
							emailMatches ||
							option.loadMore
						);
					})
				}
				disabled={disabled}
				renderOption={(props, option, { selected }) =>
					option.loadMore ? (
						<li {...props} key="load-more" onClick={loadMore}>
							<Grid container alignItems="center" spacing={2}>
								<Grid item xs={12}>
									<Typography align="center">
										{isLoading ? 'Loading...' : 'Load More'}
									</Typography>
								</Grid>
							</Grid>
						</li>
					) : (
						<li {...props} key={option?._id}>
							<FormControlLabel
								control={<Radio checked={selected} />}
								label={
									<Grid container alignItems="center" spacing={2}>
										<Grid item>
											<UserAvatarWithCircularText user={option} />
										</Grid>
										<Grid item>
											<Typography variant="body1">{`${option.firstName} ${option.lastName}`}</Typography>
											{option?.phone && (
												<Typography variant="body1">{`+${option.countryCode} ${option.phone}`}</Typography>
											)}
											{option?.email && (
												<Typography variant="body1">
													{option?.email}
												</Typography>
											)}
										</Grid>
									</Grid>
								}
							/>
						</li>
					)
				}
				renderInput={(params) => (
					<StyledCustomTextField
						{...params}
						placeholder={t('searchByUsernameOrMobile')}
						autoComplete="off"
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{isLoading ? <CircularProgress size={20} /> : null}
									{params.InputProps.endAdornment}
								</>
							),
						}}
					/>
				)}
				noOptionsText={
					handleAddUserClick ? (
						<Typography>
							{t('didntFindThePerson')}{' '}
							<Link href="#" onClick={handleAddUserClick}>
								+ {t('AddNewuser')}
							</Link>
						</Typography>
					) : (
						''
					)
				}
			/>
		</>
	);
};

export default PaginatedAutocomplete;
