export interface ICourts {
  date?: any;
  court?: any;
  startTime?: any;
  endTime?: any;
  slots?: any;
}

const cancellationSettingsString: any = localStorage.getItem("settings");

if (!cancellationSettingsString) {
  console.log("Cancellation not found in localStorage");
}

const cancellationSettingsObject = JSON.parse(cancellationSettingsString); // Parse the string into an object
const timeLimitToCancelTournament =
  cancellationSettingsObject?.timeLimitToCancelTournament; // Access the tax percentage

export const defaultCourtsValue: Readonly<ICourts> = {
  date: "",
  court: "",
  startTime: "",
  endTime: "",
  slots: [],
};

export interface IAvailability {
  club?: any;
  courts?: ICourts;
}

export const defaultAvailabilityValue: Readonly<IAvailability> = {
  club: "",
  courts: {},
};

export interface ITournament {
  _id?: any;
  name?: any;
  startDate?: any;
  endDate?: any;
  registrationDeadline?: any;
  type?: any;
  isLevelRestriction?: any;
  description?: any;
  gender?: any;
  attachments?: any;
  rulesAndRegulations?: any;
  prizeDetails?: any;
  categoryLevels?: any;
  isPublic?: any;
  enrollmentType?: any;
  minTeams?: any;
  maxTeams?: any;
  features?: any;
  clubs?: any;
  cancellationPeriodInHours?: any;
  fee?: any;
  level?: any;
  availability?: IAvailability;
  courts?: any;
}

export const defaultTournamentValue: Readonly<ITournament> = {
  _id: undefined,
  name: "",
  startDate: "",
  endDate: "",
  registrationDeadline: "",
  type: "",
  isLevelRestriction: false,
  description: "",
  gender: "",
  attachments: [],
  rulesAndRegulations: "",
  prizeDetails: "",
  categoryLevels: [],
  isPublic: true,
  enrollmentType: ["Doubles"],
  minTeams: 1,
  maxTeams: 1,
  features: [],
  level: "",
  cancellationPeriodInHours: timeLimitToCancelTournament
    ? timeLimitToCancelTournament
    : 24,
  fee: 0,
  availability: {},
  courts: [],
};

export interface ITeamPlayer {
  _id?: any;
  user?: any;
  requestStatus?: any;
  paymentStatus?: any;
  licenceNumber?: any;
  isLicenceVerified?: any;
  countryCode?: any;
  phone?: any;
  email?: any;
  gender?: any;
  firstName?: any;
  lastName?: any;
  profilePic?: any;
  playerLevel?: any;
  playerEnrollmentType?: any;
  paymentType?: any;
}

export const defaultTeamPlayer: Readonly<ITeamPlayer> = {
  _id: "",
  user: "",
  requestStatus: "",
  paymentStatus: "",
  licenceNumber: 0,
  isLicenceVerified: false,
  countryCode: 0,
  phone: "",
  email: "",
  gender: "",
  firstName: "",
  lastName: "",
  profilePic: "",
  playerLevel: "",
  playerEnrollmentType: "",
  paymentType: ''
};
